// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
// require("channels")

import $ from 'jquery'
global.$ = jQuery

import 'bootstrap'
import 'bootstrap-select'
import 'select2'
import '@fortawesome/fontawesome-free/js/all'

import 'stylesheets/application'

import 'src/firmware'
import 'src/mobile-friendly'
import 'src/sidebar'
import 'src/search'
import 'src/select'
import 'src/table-row-select'
import 'src/license_module'

FontAwesome.config.mutateApproach = 'sync'
