$(document).on('turbolinks:load', function() {
  $('select:not(.selectpicker)').selectpicker();

  $('.selectpicker.customer').select2({
    width: '100%',
    theme: "bootstrap4",
    minimumInputLength: 3,
    tags: true,
    ajax: {
      url: '/admin/customers/search',
      contentType: "application/json",
      dataType: 'json',
      data: function (params) {
        return { search: params.term }
      },
      processResults: function (data) {
        return {
          results: $.map(data, function(customer) {
            return { id: customer.id, text: customer.name };
          })
        }
      }
    }
  })

  $('.selectpicker.firmware_version').select2({
    width: '100%',
    theme: "bootstrap4",
    minimumInputLength: 2,
    tags: true,
    ajax: {
      url: '/admin/firmwares/search',
      contentType: "application/json",
      dataType: 'json',
      data: function (params) {
        return { search: params.term, group_ids: $("#device_group_ids").val() };
      },
      processResults: function (data) {
        return {
          results: $.map(data, function(firmware) {
            return { id: firmware.version, text: firmware.version };
          })
        }
      }
    }
  })

  $('.selectpicker.device_config').select2({
    width: '100%',
    theme: "bootstrap4",
    minimumInputLength: 1,
    ajax: {
      url: '/admin/device_configs/search',
      contentType: "application/json",
      dataType: 'json',
      data: function (params) {
        return { search: params.term };
      },
      processResults: function (data) {
        return {
          results: $.map(data, function(device_config) {
            return { id: device_config.id, text: device_config.name };
          })
        }
      }
    }
  })
});

document.addEventListener("turbolinks:before-cache", function() {
  $("select:not(.selectpicker)").selectpicker('destroy')
  $(".selectpicker").select2('destroy')
})
