$(document).on('mousedown', 'td.not-selectable, .btn', function (event) {
  event.stopPropagation()
  event.preventDefault()
})

$(document).on('mousedown', '.table-wrapper:not(.not-selectable) tbody tr', function () {
  if ($('.multi-action').length > 0 || $('.single-action').length > 0) {
    let tableWrapper = $(this).closest('.table-wrapper');
    if (tableWrapper.find('.multi-action').length === 0) {
      tableWrapper.find('tr').each(function(_, row) {
        if (row == this) { return; }
        row.className = '';
      }.bind(this));
    }

    if (this.className === 'active') {
      this.className = '';
    } else {
      this.className = 'active';
    }

    switch ($("tr.active").length) {
    case 0:
      $('.single-action').addClass('disabled');
      $('.multi-action:not(.deactivate_disabled)').addClass('disabled');
      break;
    case 1:
      $('.single-action').removeClass('disabled');
      $('.multi-action:not(.deactivate_disabled)').removeClass('disabled');
      break;
    default:
      $('.single-action').addClass('disabled');
      $('.multi-action:not(.deactivate_disabled)').removeClass('disabled');
      break;
    }

    $('.single-action, .multi-action').each(function() {
      $(this).attr('href', $(this).data('url').replace('placeholder', selectedIds()));
    });
  } else {
    return null
  }
});

$(document).on('click', '.table-wrapper .btn-select-all', function() {
  if ($("tr[data-id]:not([class='active'])").length > 0) {
    $("tr[data-id]:not([class='active']").trigger("mousedown")
  } else {
    $("tr[data-id][class='active']").trigger("mousedown")
  }
})

function selectedIds() {
  var ids = '';
  $('tr.active').each(function() {
    ids += $(this).attr('data-id') + ',';
  });
  return ids.slice(0, -1);
};
