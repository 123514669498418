let timeout = null

$(document).on("click", ".clear-search", function() {
  $(this).siblings().find('input')[0].value = '';
  let form = $(this).closest('form');

  clearTimeout(timeout);
  submitForm(form);
});

$(document).on("keyup", ".trigger-search", function(event) {
  clearTimeout(timeout);

  let form = $(this).closest('form');

  if (event.keyCode !== 13) {
    timeout = setTimeout(function() { submitForm(form) }, 500);
  } else if ($(".create-by-search").length > 0 && event.ctrlKey && event.keyCode === 13) {
    $(".create-by-search").click()
  }
});

function submitForm(form) {
  $.ajax({
    type: form[0].method,
    url: form[0].action,
    data: form.serialize()
  });
}

$(document).on("click", ".create-by-search", function() {
  var searchField = $(".trigger-search")

  if (searchField.val().length > 0) {
    $.ajax({
      type: "get",
      url: "/admin/customers/create_search",
      data: {
        name: searchField.val()
      }
    });
  }
})
