$(document).on('ready turbolinks:load', (event) => {
  const tableEl = document.querySelector('.mobile-friendly')

  if (tableEl) {
    const thEls = tableEl.querySelectorAll('thead th')
    const tdLabels = Array.from(thEls).map(el => el.innerText)

    tableEl.querySelectorAll('tbody tr').forEach(tr => {
      Array.from(tr.children).forEach((td, ndx) => {
        if (tdLabels[ndx].length > 0) {
          td.setAttribute('label', tdLabels[ndx]);
        }
      })
    })
  }
})
